import React from 'react';
import PropTypes from 'prop-types';

// Style and SEO
import { css, up, styled } from '@smooth-ui/core-sc';
import { Link } from 'react-router-dom';

import { withSettings } from 'containers/WebApp/SettingsContext';
import FooterLogo from './images/logo-footer-@2x.png';

const FooterWrapper = styled.footer`
  background-color: #fff;
`;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding: 1rem 0 1rem 0;
  ${up(
    'md',
    css`
      flex-direction: row;
      justify-content: center;
    `
  )};
  p {
    font-size: 14px;
    font-weight: bold;
  }
  ul {
    padding-left: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    ${up(
      'md',
      css`
        flex-direction: row;
      `
    )};
  }
  li {
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    color: #000;
    padding: 5px 0;
    ${up(
      'md',
      css`
        border-right: 1px solid #000;
        padding: inherit;
        text-align: left;
        &:nth-last-child(1) {
          border-right: none;
        }
      `
    )};
    a {
      font-size: 14px;
      font-weight: bold;
      color: #000;
      text-decoration: none;
      padding: 0.1rem 1rem;
      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

const FooterLogoWrapper = styled.div`
  display: block;
  margin-top: 2rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: auto;
  height: auto;
  width: 131px;
`;

const Footer = ({ siteName }) => (
  <FooterWrapper>
    <FooterLogoWrapper>
      <img src={FooterLogo} alt={siteName} />
    </FooterLogoWrapper>
    <FooterContainer>
      <ul>
        <li>
          <Link to="/sitemap">Sitemap</Link>
        </li>
        <li>
          <Link to="/privacy">Privacy Policy</Link>
        </li>
        <li>
          <Link to="/terms">Terms &amp; Conditions</Link>
        </li>
        <li>
          <Link to="/faqs">FAQs</Link>
        </li>
        <li>
          <Link to="/contact">Contact Us</Link>
        </li>
      </ul>
    </FooterContainer>
  </FooterWrapper>
);

Footer.propTypes = {
  siteName: PropTypes.string,
};

Footer.defaultProps = {
  siteName: 'Lori Program Default',
};

export default withSettings(Footer);
