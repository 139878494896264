/**
 * Application theme
 *
 * Override and extend smooth-ui theme values
 * https://github.com/smooth-code/smooth-ui/blob/master/packages/shared/core/theme.js
 * https://smooth-ui.smooth-code.com/
 */

import { transparentize } from 'polished';
import { thd, theme } from '@smooth-ui/core-sc';
import settingsApp from 'theme/settingsApp';

/*
If overwriting the default Smooth-UI theme properties, and you're planning on
re-using them throughout the default export below, make sure to define them in
the outer scope, otherwise components attempting to use the value with throw errors
*/
const primary = '#1A3C6C';
const secondary = '#E32726';
const grey = '#54707C';
const bluegrey = '#E32726';
const green = '#1A3C6C';
const text = '#1B1918';

export default {
  // Mandatory
  ...theme,
  settingsApp, // Bring in the merged app settings

  // Resets
  borderRadius: 0,
  error: thd('danger', '#dc3545'),
  gridMaxWidths: { sm: '100%', md: '100%', lg: '100%', xl: '1200px' },
  primary: primary,
  secondary: secondary,
  text: text,
  grey: grey,
  bluegrey: bluegrey,
  green: green,

  // Layout
  borderColor: thd('gray300', '#ddd'),
  containerWidth: '1200px',

  // Colours
  defaultFontColor: theme.gray800,
  // pointsValueColor: '#f90',

  // Input + Buttons
  inputBtnMinWidth: '120px',
  customInputHintColor: theme.gray600,
  customInputHintFontSize: '.875em',
  customInlineInputInvalidColor: theme.red,
  customCheckboxDisabledBackground: '#ced4da',

  // Header + Menu
  customHeaderBackground: '#1A3C6C',
  customHeaderColor: '#f8fbfe',
  customMenuBackground: '#1A3C6C',
  customMenuColor: '#fff',
  customMenuHighlight: '#151d29',
  customMenuHighlightColor: '#EDB633',
  customMenuFocus: transparentize(0.75, '#EDB633'),
  customSubMenuBackground: '#151d29',
  customMobileMenuOverlayBackground: 'rgba(0, 0, 0, 0.5)',

  // Loading animations
  loadingBarColor: primary || '#EDB633',
  loadingSpinnerPrimary: '#555',
  loadingSpinnerSecondary: '#eee',

  // Welcome component
  welcomeBackground: '#1A3C6C',
  welcomeColor: '#fff',
  welcomeHighlight: '#EDB633',

  // Breadcrumbs
  // breadcrumbsBackground: thd('gray200', '#fff'),
  breadcrumbsColor: thd('gray600', '#555'),
  breadcrumbsContainerWidth: thd('containerWidth', '100%'),
  breadcrumbsLinkColor: thd('gray600', '#555'),
  breadcrumbsLinkHoverColor: '#E32726',
  breadcrumbsCurrentLinkColor: thd('gray800', '#333'),
  breadcrumbsFontSize: '0.75rem',

  // Cart
  // miniCartBackground: '#fff',
  cartBadgeBackground: '#EDB633',
  cartBadgeColor: '#fff',
  cartPointsBalanceBackground: transparentize(0.75, '#369'),
  cartRemainingNegativeColor: thd('danger', '#dc3545'),

  // Rewards
  rewardCategoriesListContainerWidth: thd('containerWidth', '100%'),
  rewardCategoriesFilterContainerWidth: thd('containerWidth', '100%'),
  rewardListContainerWidth: thd('containerWidth', '100%'),
  rewardDetailContainerWidth: thd('containerWidth', '100%'),
  rewardDescription1ContainerWidth: thd('containerWidth', '100%'),
  rewardDescription2ContainerWidth: thd('containerWidth', '100%'),

  // Rewards - Quickview
  // quickviewBackground: '#333',
  // quickviewColor: '#fff',

  // Rewards - Wishlist
  miniWishlistBackground: '#fff',
  wishlistBadgeBackground: '#EDB633',
  wishlistBadgeColor: '#fff',
  wishlistContainerWidth: '500px',
  wishlistIconActive: '#EDB633',
  miniWishlistIcon: '#fff',
  wishlistIconInactive: theme.gray500,

  // Rewards - Highlight (featured/related rewards)
  rewardsHighlightBackground: '#fff',
  rewardsHighlightContainerWidth: thd('containerWidth', '100%'),

  // Statement
  statementHighlightColor: '#4b4442',
  statementStatus: {
    achieved: '#1e967a',
    onTrack: '#1e967a',
    close: '#ea7c22',
    behind: '#dc402a',
    inProgress: '#ea7c22',
    timeElapsed: '#EDB633',
  },
  statementDoughnutBorder: '#eaeaeb',
  statementDoughnutRemainingTrack: '#D8D8D8',

  // Tooltips
  tooltipBackground: '#fff',
  tooltipBorderColor: '#fff',
  tooltipColor: thd('defaultFontColor', theme.gray800),

  // Pagination
  pagerActiveBackground: 'rgba(0,0,0,0.125)',
  pagerActiveColor: '#fff',
  // pagerColor: thd('gray800', 'inherit'),
  pagerBackgroundHover: 'rgba(0,0,0,0.125)',
};
